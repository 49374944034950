import React, { ReactNode, useState } from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  CardProps,
  Checkbox,
  HStack,
  Icon,
  Stack,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useLocation } from 'wouter'
import UnfoldMoreIcon from '@material-design-icons/svg/sharp/unfold_more.svg?react'

import { CardRow } from '@/components/Card/CardRow'
import ListDefaults from '@/components/ListDefaults'
import { MayhemPagination } from '@/components/MayhemPagination'
import { useQuery } from '@/hooks'
import { setQueryParams } from '@/util/location'

export interface Column<T> {
  key: string
  headingText: string
  sortHeading?: {
    ascending: string | React.ReactNode
    descending: string | React.ReactNode
  }
  cellAlignText?: 'left' | 'right'
  sorting?: boolean
  cell: (row: T) => React.ReactNode
  visible?: boolean
  TableCellProps?: TableCellProps
  TableColumnHeaderProps?: TableColumnHeaderProps
}

interface Props<T> {
  columns: Column<T>[]
  items: T[]
  itemName: string
  rowClickPath?: (row: T) => string
  onRowClick?: (row: T) => void
  onCheckboxClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  rowKey: (row: T) => string | number
  isError: boolean
  isLoading: boolean
  count: number
  showCheckbox?: boolean
  isRowSelected?: (row: T) => boolean
  initialSortBy: string
  initialOrderBy?: string
  CardProps?: CardProps
  encodeDescWithNegation?: boolean
  refetchRows?: () => void
  hideCardBorder?: boolean
}

export default function ListingTable<T>({
  items,
  columns,
  rowClickPath,
  onRowClick,
  rowKey,
  isError,
  isLoading,
  count,
  showCheckbox = false,
  itemName,
  initialSortBy,
  initialOrderBy,
  onCheckboxClick,
  CardProps,
  isRowSelected,
  encodeDescWithNegation = false,
  hideCardBorder = false
}: Props<T>) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  let initialSortedBy = queryParams.get('sorted_by') || initialSortBy
  let initialOrderedBy = queryParams.get('ordered_by') || initialOrderBy || 'desc'
  let sanitizedInitialSortBy = initialSortBy
  if (encodeDescWithNegation) {
    if (initialSortBy.startsWith('-')) {
      sanitizedInitialSortBy = initialSortBy.slice(1)
    }
    if (initialSortedBy.startsWith('-')) {
      initialSortedBy = initialSortedBy.slice(1)
      initialOrderedBy = 'desc'
    } else {
      initialOrderedBy = 'asc'
    }
  }

  const [sortedBy, setSortedBy] = useState(initialSortedBy)
  const [orderedBy, setOrderedBy] = useState(initialOrderedBy)

  const handleSort = (column: string) => {
    let newSortedBy = sortedBy
    let newOrderedBy = orderedBy

    if (sortedBy === column) {
      if (orderedBy === 'desc') {
        newOrderedBy = 'asc'
      } else if (orderedBy === 'asc') {
        newSortedBy = ''
        newOrderedBy = ''
      } else {
        newOrderedBy = 'desc'
      }
    } else {
      newSortedBy = column
      newOrderedBy = 'desc'
    }
    let params: { param: string; value: string | undefined }[]
    if (encodeDescWithNegation) {
      params = [
        {
          param: 'sorted_by',
          value: ((newOrderedBy || initialOrderBy || 'desc') == 'desc' ? '-' : '') + column
        }
      ]
    } else {
      params = [
        { param: 'sorted_by', value: newSortedBy || undefined },
        { param: 'ordered_by', value: newOrderedBy || undefined }
      ]
    }
    const newParams = setQueryParams({
      location,
      queryParams,
      params
    })
    setLocation(newParams)
    setSortedBy(newSortedBy || sanitizedInitialSortBy)
    setOrderedBy(newOrderedBy || initialOrderBy || 'desc')
  }

  const getSortIndicator = (column: string, ascendingLabel: ReactNode, descendingLabel: ReactNode) => {
    if (sortedBy === column) {
      return (
        <>
          <Tag padding={0} justifyContent="center" borderRadius="lg">
            <Icon as={UnfoldMoreIcon} boxSize={5} />
          </Tag>
          <Tag paddingX={1} justifyContent="center" borderRadius="lg">
            {orderedBy === 'asc' ? ascendingLabel : descendingLabel}
          </Tag>
        </>
      )
    }

    return <Icon as={UnfoldMoreIcon} boxSize={5} color="faded" />
  }

  return (
    <Stack gap={4}>
      <Card width="full" {...CardProps} borderStyle={hideCardBorder ? 'none' : undefined}>
        <CardBody>
          <Stack>
            <TableContainer>
              <Table variant="simple" sx={{ borderBottom: 'none' }}>
                <Thead>
                  <Tr>
                    {showCheckbox && <Th></Th>}
                    {columns
                      .filter(({ visible = true }) => visible)
                      .map(({ headingText, key, sorting = true, sortHeading, TableColumnHeaderProps }) => (
                        <Th {...(sorting ? { onClick: () => handleSort(key), cursor: 'pointer' } : {})} {...TableColumnHeaderProps} key={key}>
                          <HStack gap={1}>
                            <Text>{headingText}</Text>
                            {sorting && getSortIndicator(key, sortHeading?.ascending || 'ASC', sortHeading?.descending || 'DESC')}
                          </HStack>
                        </Th>
                      ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {!isLoading &&
                    items.map((row: T) => {
                      const isSelected = Boolean(isRowSelected?.(row))

                      return (
                        <CardRow
                          as={Tr}
                          key={rowKey(row)}
                          aria-selected={isSelected}
                          {...(rowClickPath || onRowClick
                            ? {
                                onClick: (e: React.MouseEvent) => {
                                  e.preventDefault()

                                  if (rowClickPath) {
                                    if (e.metaKey || e.ctrlKey) {
                                      // Not using WouterLinkOverlay because it breaks tooltip within cell
                                      window.open(rowClickPath(row) as string, '_blank')
                                      return
                                    }

                                    setLocation(rowClickPath(row))
                                  }
                                  if (onRowClick) {
                                    onRowClick(row)
                                  }
                                }
                              }
                            : { sx: { cursor: 'default' } })}
                        >
                          {showCheckbox && (
                            <Td textAlign="right">
                              <Checkbox variant="outline" isChecked={isSelected} width={4} onClick={onCheckboxClick} />
                            </Td>
                          )}
                          {columns
                            .filter(({ visible = true }) => visible)
                            .map(({ cell, key, cellAlignText, TableCellProps }) => (
                              <Td key={key} {...(cellAlignText ? { textAlign: cellAlignText } : {})} {...TableCellProps}>
                                {cell(row)}
                              </Td>
                            ))}
                        </CardRow>
                      )
                    })}
                  <Td colSpan={columns.length} border="none" padding={0}>
                    <ListDefaults isLoading={isLoading} nItems={items.length} itemName={itemName} isFail={isError} />
                  </Td>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </CardBody>
        <CardFooter justifyContent="center">
          <MayhemPagination isLoading={isLoading} total={count || 0} />
        </CardFooter>
      </Card>
    </Stack>
  )
}
