import { Center, Heading, Stack, StackDivider, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'

import ListFooter from '../../components/ListFooter'
import { cleanInteger } from '../../util/numbers'
import { useAppSelector, useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetProjectMemberPermissionQuery, useGetProjectUserMembersQuery } from '../../redux/api/projects'
import { getCurrentUserInfo } from '../auth/utils'

import { ContributorsRow } from './ContributorsRow'
import { AddMemberToProjectForm } from './AddMemberToProjectForm'

type Props = { workspace: string; projectSlug: string }

export function ContributorsTab({ workspace, projectSlug }: Props) {
  const query = useQuery()
  const page = cleanInteger(query.get('userspage'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const { userSlug: currentUserSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})

  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspace,
    projectSlug,
    userSlug: currentUserSlug
  })

  const {
    data: userMembersResponse,
    refetch,
    isFetching,
    isError
  } = useGetProjectUserMembersQuery({
    owner: workspace,
    projectSlug,
    perPage,
    offset: (page - 1) * perPage
  })

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  const contributors = userMembersResponse?.users || []
  const count = userMembersResponse?.count || 0

  return (
    <Stack spacing={8} mt={8} divider={<StackDivider />}>
      <Heading size="md">Existing User Contributors</Heading>
      {isAtLeastWritePermission && <AddMemberToProjectForm workspace={workspace} projectSlug={projectSlug} />}
      <TableContainer>
        <Table layout="fixed">
          <Thead>
            <Tr>
              <Th width="25%" textAlign="left">
                User
              </Th>
              <Th width="25%" textAlign="left">
                Name
              </Th>
              <Th width="18.75%" textAlign="left">
                Permission
              </Th>
              <Th width="6.25%" textAlign="center">
                Remove
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {contributors.map((contributor) => (
              <ContributorsRow key={contributor.slug} workspace={workspace} projectSlug={projectSlug} user={contributor} onDelete={refetch} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Center>
        <ListFooter isLoading={isFetching} nItems={contributors.length} total={count} itemName="user" isFail={isError} prefix="users" />
      </Center>
    </Stack>
  )
}
