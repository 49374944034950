import { Heading, Icon, PopoverBody, PopoverHeader, Skeleton, Stack, StackDivider } from '@chakra-ui/react'

import { Link } from 'wouter'
import AccountCircleIcon from '@material-design-icons/svg/sharp/account_circle.svg?react'
import TimerIcon from '@material-design-icons/svg/sharp/timer.svg?react'
import WorkerIcon from '@material-design-icons/svg/sharp/developer_board.svg?react'

import { RunStatusSpinner } from './RunStatusSpinner'

import { RunStatusText } from './RunStatusText'

import { formatRunDuration } from './utils'

import RunStatusHoverSection from './RunStatusHoverSection'

import { formatDatetime, parseApiDate } from '@/util/time'
import { useGetRunQuery } from '@/redux/api/runs'
import { ErrorPanel } from '@/components/ErrorPanel'
import { formatNumber } from '@/util/numbers'

import RunRowParentLinks from '@/features/runs/RunRowParentLinks'

// used for worker memory info
const BYTES_PER_GB = 1073741824

export const RunStatusHover = ({ showLatestActiveRunHeader = false, ...params }: Props): JSX.Element => {
  const { owner, projectSlug, targetSlug, runNumber } = params
  const { isLoading, data: run, isError } = useGetRunQuery(params)

  if (isLoading || !run) {
    return <Skeleton />
  }

  if (isError) {
    return <ErrorPanel msg="Could not find this run" />
  }

  const {
    started_by: startedBy,
    created_at,
    started_at: startedAt = null,
    ended_at: endedAt = null,
    duration = null,
    api_run: isApiRun,
    current_workers: currentWorkers = 0,
    n_total_cpu: nTotalCpu = 0,
    n_total_memory: nTotalMemory = 0,
    desired_workers: desiredWorkers = 0,
    n_desired_cpu: nDesiredCpu = 0,
    n_desired_memory: nDesiredMemory = 0
  } = run
  const createdAt = parseApiDate(created_at)
  const durationDisplay = formatRunDuration(startedAt, endedAt, isApiRun ? 'api' : 'code', duration)

  const needsMoreWorkers = desiredWorkers !== currentWorkers
  const workersString = needsMoreWorkers ? `Assigned Workers: ${desiredWorkers}` : `Workers: ${currentWorkers}`
  const hasCpuAndMemory = (needsMoreWorkers && !!nDesiredCpu && !!nDesiredMemory) || (!needsMoreWorkers && !!nTotalMemory && nTotalCpu)
  const cpuString = `${needsMoreWorkers ? nDesiredCpu : nTotalCpu} CPU cores`
  const memoryString = `${
    needsMoreWorkers ? formatNumber(nDesiredMemory / BYTES_PER_GB, { shorten: false }) : formatNumber(nTotalMemory / BYTES_PER_GB, { shorten: false })
  }GB memory`

  const runData = [
    {
      key: 'phase-state',
      icon: <RunStatusSpinner indicatorSize="sm" {...{ owner, projectSlug, targetSlug, runNumber }} />,
      data: [{ header: 'Status', description: <RunStatusText {...{ owner, projectSlug, targetSlug, runNumber }} /> }],
      isShown: true
    },

    {
      key: 'started-by',
      icon: <Icon as={AccountCircleIcon} />,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data: [{ header: 'Started By', description: <Link to={`/${startedBy!}`}>{startedBy}</Link> }],
      isShown: !!startedBy
    },
    {
      key: 'run-started',
      icon: <Icon as={TimerIcon} />,
      data: [
        { header: 'Run Started', description: formatDatetime(createdAt) || '' },
        { header: 'Duration', description: durationDisplay }
      ],
      isShown: !!createdAt && durationDisplay
    },
    {
      key: 'currentWorkers',
      icon: <Icon as={WorkerIcon} />,
      data: [{ header: workersString, description: hasCpuAndMemory ? `(${cpuString}, ${memoryString})` : '' }],
      isShown: hasCpuAndMemory
    }
  ]

  return (
    <>
      {showLatestActiveRunHeader && (
        <PopoverHeader>
          <Stack>
            <Heading size="sm">Latest Active Run</Heading>
            <RunRowParentLinks owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} fadedText={false} isAccessible />
          </Stack>
        </PopoverHeader>
      )}
      <PopoverBody>
        <Stack spacing={1} divider={<StackDivider />}>
          {runData
            .filter((d) => d.isShown)
            .map(({ key, ...rest }) => (
              <RunStatusHoverSection key={key} {...rest} />
            ))}
        </Stack>
      </PopoverBody>
    </>
  )
}

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  showLatestActiveRunHeader?: boolean
}
