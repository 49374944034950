import React from 'react'

import { DEFAULT_PER_PAGE_OPTIONS } from '../redux/api/mayhemApi'

import ListDefaults from './ListDefaults'
import { MayhemPagination } from './MayhemPagination'

export default function ListFooter(props: Props): JSX.Element {
  const {
    isLoading = false,
    itemName = '',
    itemNamePlural = '',
    nItems = 0,
    isFail = false,
    noResultsMsg,
    hideNoResultsMsg = false,
    failureMsg,
    mini = false,
    total = 0,
    pageSizeOptions = DEFAULT_PER_PAGE_OPTIONS,
    prefix = ''
  } = props

  return (
    <React.Fragment>
      <ListDefaults
        isLoading={isLoading}
        itemName={itemName}
        itemNamePlural={itemNamePlural}
        nItems={nItems}
        isFail={isFail}
        noResultsMsg={noResultsMsg}
        hideNoResultsMsg={hideNoResultsMsg}
        failureMsg={failureMsg}
        mini={mini}
      />
      {!mini && !!total && <MayhemPagination isLoading={isLoading} total={total} pageSizeOptions={pageSizeOptions} prefix={prefix} />}
    </React.Fragment>
  )
}

interface Props {
  isLoading?: boolean
  itemName?: string
  itemNamePlural?: string
  nItems?: number
  isFail?: boolean
  noResultsMsg?: string | JSX.Element
  hideNoResultsMsg?: boolean
  failureMsg?: string
  mini?: boolean
  total?: number
  pageSizeOptions?: number[]
  prefix?: string
}
