import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'

import { useCallback, useEffect } from 'react'

import { useSearchTeamsQuery } from '../../redux/api/teams'

import { getWorkspacePageTitle } from '../../titles'

import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { cleanInteger } from '../../util/numbers'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'

import { TeamsListRow } from './TeamsListRow'
import { CreateNewTeamModal } from './CreateNewTeamModal'

interface Props {
  allowWrite: boolean
  workspace: string
}

const paginationPrefix = 'teams-'

export function TeamsTab({ allowWrite, workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Teams')
  }, [workspace])

  const query = useQuery()
  const page = cleanInteger(query.get(`${paginationPrefix}page`), 1)
  const perPage = cleanInteger(query.get(`${paginationPrefix}perPage`), DEFAULT_PER_PAGE)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    currentData: teamsResponse,
    isFetching,
    isError,
    refetch
  } = useSearchTeamsQuery({
    workspace: workspace,
    perPage,
    offset: (page - 1) * perPage
  })
  const { teams = [], count = 0 } = teamsResponse || {}

  const onCreateNewTeamModalClosed = useCallback(() => {
    onClose()
    refetch()
  }, [onClose, refetch])

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Teams</Heading>
        <Spacer />
        {allowWrite && <Button onClick={onOpen}>Create New Team</Button>}
      </CardHeader>
      <CardBody>
        <TableContainer>
          <Table layout="fixed">
            <Thead>
              <Tr>
                <Th width="75%" textAlign="left">
                  Team
                </Th>
                <Th width="25%" textAlign="center">
                  Remove
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {teams && teams.map((team) => team.slug && <TeamsListRow team={team} key={team.slug} workspace={workspace} onDelete={refetch} />)}
            </Tbody>
          </Table>
        </TableContainer>
        <ListDefaults isLoading={isFetching} mini={true} nItems={teams.length} itemName="team" isFail={isError} />
        <CreateNewTeamModal open={isOpen} onClose={onCreateNewTeamModalClosed} workspace={workspace} />
      </CardBody>

      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isFetching} total={count} prefix={paginationPrefix} />
      </CardFooter>
    </Card>
  )
}
