import { HStack } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { useQuery } from '../../hooks'
import { updateQueryParams } from '../../util/location'

import { DefectsTagV2 } from './DefectsTagV2'

import { SeverityCategory, categoryFromScore } from '@/features/run-api-defects/runApiDefects.slice'

export interface RunDefectsSeverityBreakdownProps {
  high: number
  medium: number
  low: number
}

export const RunDefectsSeverityBreakdown = ({ high, medium, low }: RunDefectsSeverityBreakdownProps) => {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const updateFilter = (severityFilter?: string) => {
    updateQueryParams(setLocation, location, { severity: severityFilter }, query)
  }

  return (
    <HStack spacing={8}>
      <DefectsTagV2 severity="High" tagLabel="H" count={high} onClick={() => updateFilter('high')} />

      <DefectsTagV2 severity="Medium" tagLabel="M" count={medium} onClick={() => updateFilter('medium')} />

      <DefectsTagV2 severity="Low" tagLabel="L" count={low} onClick={() => updateFilter('low')} />

      <DefectsTagV2 severity="Total" tagLabel="Total" count={high + medium + low} onClick={() => updateFilter()} />
    </HStack>
  )
}

export const LabeledDefectTagNum = ({ severityNumber }: { severityNumber: number }) => {
  switch (categoryFromScore(severityNumber)) {
    case SeverityCategory.High:
      return <DefectsTagV2 severity="High" count={severityNumber} />
    case SeverityCategory.Medium:
      return <DefectsTagV2 severity="Medium" count={severityNumber} />
  }
  return <DefectsTagV2 severity="Low" count={severityNumber} />
}
