export function mapEmptyArrayUndefined<T>(ary: T[] | undefined | null): T[] | undefined {
  if (!ary || !ary.length) {
    return undefined
  }
  return ary
}

export function mapIfNotNullish<T, R>(value: T | null | undefined, mappingFn: (arg: T) => R): undefined | null | R {
  if (value === null || value === undefined) {
    return value as undefined | null
  }
  return mappingFn(value)
}
