import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['project', 'internal', 'run', 'builds'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getProjectsByOwner: build.query<GetProjectsByOwnerApiResponse, GetProjectsByOwnerApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage, q: queryArg.q }
        }),
        providesTags: ['project']
      }),
      postProject: build.mutation<PostProjectApiResponse, PostProjectApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project`, method: 'POST', body: queryArg.project }),
        invalidatesTags: ['project']
      }),
      getProjectsByUser: build.query<GetProjectsByUserApiResponse, GetProjectsByUserApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/user/${queryArg.userSlug}/project`,
          params: {
            end_date: queryArg.endDate,
            offset: queryArg.offset,
            ordered_by: queryArg.orderedBy,
            per_page: queryArg.perPage,
            project_slug: queryArg.projectSlug,
            q: queryArg.q,
            sorted_by: queryArg.sortedBy,
            start_date: queryArg.startDate,
            tags: queryArg.tags
          }
        }),
        providesTags: ['internal']
      }),
      getAllProjects: build.query<GetAllProjectsApiResponse, GetAllProjectsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/project`,
          params: {
            end_date: queryArg.endDate,
            offset: queryArg.offset,
            ordered_by: queryArg.orderedBy,
            per_page: queryArg.perPage,
            project_slug: queryArg.projectSlug,
            q: queryArg.q,
            sorted_by: queryArg.sortedBy,
            start_date: queryArg.startDate,
            tags: queryArg.tags
          }
        }),
        providesTags: ['internal']
      }),
      getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}` }),
        providesTags: ['project']
      }),
      deleteProject: build.mutation<DeleteProjectApiResponse, DeleteProjectApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}`, method: 'DELETE' }),
        invalidatesTags: ['project']
      }),
      putProject: build.mutation<PutProjectApiResponse, PutProjectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}`,
          method: 'PUT',
          body: queryArg.projectUpdate
        }),
        invalidatesTags: ['project']
      }),
      getProjectInfo: build.query<GetProjectInfoApiResponse, GetProjectInfoApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/project-total` }),
        providesTags: ['project']
      }),
      postProjectUserMember: build.mutation<PostProjectUserMemberApiResponse, PostProjectUserMemberApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/user`,
          method: 'POST',
          body: queryArg.projectUser
        }),
        invalidatesTags: ['internal']
      }),
      getProjectUserMembers: build.query<GetProjectUserMembersApiResponse, GetProjectUserMembersApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/user`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['internal']
      }),
      putProjectUserMember: build.mutation<PutProjectUserMemberApiResponse, PutProjectUserMemberApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/user/${queryArg.userSlug}`,
          method: 'PUT',
          body: queryArg.projectMemberPermission
        }),
        invalidatesTags: ['internal']
      }),
      deleteProjectUserMember: build.mutation<DeleteProjectUserMemberApiResponse, DeleteProjectUserMemberApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/user/${queryArg.userSlug}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      }),
      getProjectMemberPermission: build.query<GetProjectMemberPermissionApiResponse, GetProjectMemberPermissionApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/user-permission/${queryArg.userSlug}` }),
        providesTags: ['internal']
      }),
      getProjectRuns: build.query<GetProjectRunsApiResponse, GetProjectRunsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/run`,
          params: {
            end_date: queryArg.endDate,
            offset: queryArg.offset,
            ordered_by: queryArg.orderedBy,
            per_page: queryArg.perPage,
            run_type: queryArg.runType,
            search: queryArg.search,
            sorted_by: queryArg.sortedBy,
            start_date: queryArg.startDate,
            status: queryArg.status,
            target_slug: queryArg.targetSlug
          }
        }),
        providesTags: ['run']
      }),
      getRunStatsByProject: build.query<GetRunStatsByProjectApiResponse, GetRunStatsByProjectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/run-stat`,
          params: { target: queryArg.target }
        }),
        providesTags: ['run']
      }),
      getProjectBuilds: build.query<GetProjectBuildsApiResponse, GetProjectBuildsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/build`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['builds']
      }),
      getProjectBuild: build.query<GetProjectBuildApiResponse, GetProjectBuildApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/build/${queryArg.build}` }),
        providesTags: ['builds']
      }),
      getBuildRuns: build.query<GetBuildRunsApiResponse, GetBuildRunsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/build/${queryArg.build}/run`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['run']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetProjectsByOwnerApiResponse = /** status 200 Returns list of projects within the given owner.

    Returns:
        list(Project) -- list of project objects in the owner.
     */ ProjectList
export type GetProjectsByOwnerApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  q?: string
}
export type PostProjectApiResponse = /** status 200 Creates a new project in the given owner.

    Returns:
        Project -- The project object that was created.
     */ Project
export type PostProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  project: Project
}
export type GetProjectsByUserApiResponse = /** status 200
    Returns viewable projects that user_slug has been given access to,
    either by being a member of that project or because the user is the
    account owner of project's owner.

    Note: the route preserves the functionality for admin users, i.e., admin
    users will only see the projects they have been given access to.
     */ ProjectList
export type GetProjectsByUserApiArg = {
  userSlug: string
  endDate?: string
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  projectSlug?: string[]
  q?: string
  /** The argument to be sorted by. */
  sortedBy?: string
  startDate?: string
  tags?: ('api' | 'code')[]
}
export type GetAllProjectsApiResponse = /** status 200 Gets all projects on the cluster. This route is for admin use only. */ ProjectList
export type GetAllProjectsApiArg = {
  endDate?: string
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  projectSlug?: string[]
  q?: string
  /** The argument to be sorted by. */
  sortedBy?: string
  startDate?: string
  tags?: ('api' | 'code')[]
}
export type GetProjectApiResponse = /** status 200 Retrieves a single project that matches the given
    owner and project name.

    Returns:
        Project -- The matching project.
     */ Project
export type GetProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type DeleteProjectApiResponse = /** status 200 Deletes a single project from a owner.

    Returns:
        {"result": "ok"} -- On successful deletion.
     */ Ok
export type DeleteProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type PutProjectApiResponse = /** status 200 Updates a single project from a owner.

    Returns:
        {"result": "ok"} -- On successful update.
     */ Project
export type PutProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  projectUpdate: ProjectUpdate
}
export type GetProjectInfoApiResponse = /** status 200 Get totals for a project in a owner.

    :param project: the desired project object

    :returns: A dict with keys `targets`, `runs`, `latest_defects`,
              `latest_crashes`, `latest_testcases` with integer values of counts
     */ ProjectTotals
export type GetProjectInfoApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type PostProjectUserMemberApiResponse = /** status 200 Add a user to the organization */ Ok
export type PostProjectUserMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  projectUser: ProjectUser
}
export type GetProjectUserMembersApiResponse = /** status 200 Return all project user members. */ ProjectUsers
export type GetProjectUserMembersApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type PutProjectUserMemberApiResponse = /** status 200 Update ProjectUserMember */ Ok
export type PutProjectUserMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  userSlug: string
  projectMemberPermission: ProjectMemberPermission
}
export type DeleteProjectUserMemberApiResponse = /** status 200 Remove a member from an org */ Ok
export type DeleteProjectUserMemberApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  userSlug: string
}
export type GetProjectMemberPermissionApiResponse = /** status 200 Get the project permission for this user */ ProjectMemberPermission
export type GetProjectMemberPermissionApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  userSlug: string
}
export type GetProjectRunsApiResponse = /** status 200 Returns list of runs for the given owner and project in
    reverse chronological order.

    Returns:
        list(Run) -- list of run objects from the target.
     */ RunsPage
export type GetProjectRunsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  endDate?: string
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  runType?: 'api' | 'code'
  search?: string
  /** The argument to be sorted by. */
  sortedBy?: string
  startDate?: string
  status?: ('pending' | 'running' | 'completed' | 'failed' | 'stopped')[]
  targetSlug?: string[]
}
export type GetRunStatsByProjectApiResponse = /** status 200 Get stats for runs for a project in a owner, optionally filtered by target.

    The target to filter on is determined by the target slug passed in the query
    parameter `target`.

    :param owner: the owner to which the desired project belongs
    :param project_slug: the slug of the desired project

    :returns: a dict with keys `count` and `active` and integer values
     */ ProjectRunStat
export type GetRunStatsByProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  target?: string
}
export type GetProjectBuildsApiResponse = /** status 200 Returns list of projects within the given owner.

    Returns:
        list(Build) -- list of build objects in the project.
        Int count -- total number of build objects in the project
     */ BuildList
export type GetProjectBuildsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetProjectBuildApiResponse = /** status 200 Retrieves a single build that matches the given
    owner, project name and ci url.

    Returns:
        Build -- The matching build.
     */ Build
export type GetProjectBuildApiArg = {
  build: string
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
}
export type GetBuildRunsApiResponse = /** status 200 Returns list of projects within the given owner.

    Returns:
        list(Project) -- list of project objects in the owner.
     */ RunGetSchemaWithTargetSlug
export type GetBuildRunsApiArg = {
  build: string
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type RunPhase = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
}
export type RunPhaseCurrent = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
  name?: string
  description?: string
  value?: number | null
}
export type EndpointCoverageV1Entry = {
  method: string
  path: string
  status?: number
  hits: number
  tags: string[]
}
export type EndpointCoverageV1 = {
  entries: EndpointCoverageV1Entry[]
}
export type EndpointCoverage = {
  V1: EndpointCoverageV1
}
export type EndpointLatencyV1Entry = {
  method: string
  path: string
  buckets: number[]
  hits: number[]
  tags: string[]
}
export type EndpointLatencyV1 = {
  entries: EndpointLatencyV1Entry[]
}
export type EndpointLatency = {
  V1: EndpointLatencyV1
}
export type GetApiRun = {
  created_at?: string
  updated_at?: string
  duration_mode: 'AUTOMATIC' | 'TIME' | 'GOAL'
  spec_source?: 'OPENAPI' | 'HAR_OPENAPI' | 'POSTMANCOLLECTION_OPENAPI' | 'SWAGGERV2_OPENAPI' | 'POSTMANCOLLECTION' | 'REPLAY' | 'UNKNOWN'
  url?: string
  progress_sec: number
  progress_percent: number
  status: 'PENDING' | 'RUNNING' | 'COMPLETE' | 'CANCELLED' | 'FAILED'
  total_endpoint_count?: number
  unauthed_endpoint_count?: number
  unsuccessful_endpoint_count?: number
  latency_p50_millis?: number
  latency_p90_millis?: number
  latency_p99_millis?: number
  endpoint_coverage?: EndpointCoverage
  endpoint_latency?: EndpointLatency
  sarif_sha?: string
  junit_sha?: string
  html_report_sha?: string
  reports_zip_sha?: string
}
export type RunGetSchemaWithMetadata = {
  created_at?: string
  updated_at?: string
  run_id: number
  run_number?: number
  target_id: number
  is_deleted?: boolean
  build_id?: string
  duration?: number | null
  task_id?: string
  tests_run_symexec?: number
  tests_run_mfuzz?: number
  tests_run_libfuzz?: number
  tests_run_honggfuzz?: number
  tests_per_second?: number
  edges_covered?: number
  run_attributes?: object | null
  cli_version?: string | null
  ci_type?: string | null
  ci_url?: string | null
  branch_name?: string | null
  merge_base_branch_name?: string | null
  parent_revision?: string | null
  revision?: string | null
  scm_remote_origin?: string | null
  scm_commit_author?: string | null
  scm_commit_author_email?: string | null
  scm_commit_subject?: string | null
  interface_info?: object
  cputime?: number
  elapsed_real_time?: number | null
  docker_registry_id?: number | null
  docker_pullable?: string
  fetched_docker_image_digest?: string
  base_testsuite_id?: number | null
  user_testsuite_id?: number | null
  output_testsuite_id?: number | null
  is_pure_docker?: boolean
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  started_by?: string
  is_mayhem_bot?: boolean
  n_starting_testcases?: number | null
  docker_registry_url?: string
  phases?: RunPhase[]
  tests_run?: number
  n_testcase_reports?: number
  n_behavior_testcase_reports?: number
  n_regression_testcase_reports?: number
  n_regression_defect_reports?: number
  n_crash_reports?: number
  n_behavior_crash_reports?: number
  n_regression_crash_reports?: number
  n_defects?: number
  n_behavior_defects?: number
  n_regression_defects?: number
  n_valgrind_errors?: number
  n_behavior_valgrind_errors?: number
  n_regression_valgrind_errors?: number
  n_exploitability_factors_passing?: number
  n_exploitability_factors_total?: number
  started_at?: string | null
  ended_at?: string | null
  pending?: boolean
  started?: boolean
  stopped?: boolean
  ended?: boolean
  failed?: boolean
  has_critical_errors?: boolean
  description?: string
  name?: string
  phase?: RunPhaseCurrent
  owner?: string
  owner_name?: string
  target_slug?: string
  target_name?: string
  project_slug?: string
  project_name?: string
  api_run?: GetApiRun
}
export type Project = {
  created_at?: string
  updated_at?: string
  project_id?: number
  project_name: string
  slug?: string
  is_deleted?: boolean
  owner_id?: number
  namespace?: string
  owner?: string
  namespace_name?: string
  owner_name?: string
  has_code_run?: boolean
  has_api_run?: boolean
  latest_active_run?: RunGetSchemaWithMetadata | null
  repository_url?: string | null
  access_level?: 'PRIVATE' | 'AUTHENTICATED' | 'PUBLIC'
  allow_concurrent_runs?: boolean | null
  prune_backlog?: boolean
  testcase_limit_enabled?: boolean | null
  settings?: object
  default_branch?: string | null
  public_badge_share?: boolean
  n_defects_active_high?: number
  n_defects_active_medium?: number
  n_defects_active_low?: number
  n_defects_fixed_high?: number
  n_defects_fixed_medium?: number
  n_defects_fixed_low?: number
}
export type ProjectList = {
  projects?: Project[]
  count?: number
}
export type ApiError = {
  message?: string
}
export type Ok = {
  result?: string
}
export type ProjectUpdate = {
  created_at?: string
  updated_at?: string
  access_level?: 'PRIVATE' | 'AUTHENTICATED' | 'PUBLIC'
  allow_concurrent_runs?: boolean | null
  prune_backlog?: boolean | null
  testcase_limit_enabled?: boolean | null
  public_badge_share?: boolean
  default_branch?: string | null
}
export type TargetCounts = {
  api: number
  code: number
}
export type ProjectTotals = {
  targets: number
  target_counts?: TargetCounts
  runs: number
  defects_total: number
  latest_defects: number
  latest_crashes: number
  latest_testcases: number
  latest_targets_passing_tests: number
}
export type ProjectUser = {
  created_at?: string
  updated_at?: string
  slug: string
  permission?: 'READ' | 'WRITE' | 'ADMIN'
  first_name?: string | null
  last_name?: string | null
  active?: boolean
}
export type ProjectUsers = {
  created_at?: string
  updated_at?: string
  users?: ProjectUser[]
  count?: number
}
export type ProjectPermissionContext = {
  created_at?: string
  updated_at?: string
  org?: 'READ' | 'WRITE' | 'ADMIN'
  user?: 'READ' | 'WRITE' | 'ADMIN'
  team?: 'READ' | 'WRITE' | 'ADMIN'
}
export type ProjectMemberPermission = {
  created_at?: string
  updated_at?: string
  permission: 'READ' | 'WRITE' | 'ADMIN'
  context?: ProjectPermissionContext
}
export type RunListing = {
  created_at?: string
  updated_at?: string
  run_id: number
  run_number?: number
  target_id: number
  is_deleted?: boolean
  build_id?: string
  duration?: number | null
  task_id?: string
  tests_run_symexec?: number
  tests_run_mfuzz?: number
  tests_run_libfuzz?: number
  tests_run_honggfuzz?: number
  tests_per_second?: number
  edges_covered?: number
  run_attributes?: object | null
  cli_version?: string | null
  ci_type?: string | null
  ci_url?: string | null
  branch_name?: string | null
  merge_base_branch_name?: string | null
  parent_revision?: string | null
  revision?: string | null
  scm_remote_origin?: string | null
  scm_commit_author?: string | null
  scm_commit_author_email?: string | null
  scm_commit_subject?: string | null
  interface_info?: object
  cputime?: number
  elapsed_real_time?: number | null
  docker_registry_id?: number | null
  docker_pullable?: string
  fetched_docker_image_digest?: string
  base_testsuite_id?: number | null
  user_testsuite_id?: number | null
  output_testsuite_id?: number | null
  is_pure_docker?: boolean
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  started_by?: string
  is_mayhem_bot?: boolean
  n_starting_testcases?: number | null
  docker_registry_url?: string
  phases?: RunPhase[]
  tests_run?: number
  n_testcase_reports?: number
  n_behavior_testcase_reports?: number
  n_regression_testcase_reports?: number
  n_regression_defect_reports?: number
  n_crash_reports?: number
  n_behavior_crash_reports?: number
  n_regression_crash_reports?: number
  n_defects?: number
  n_behavior_defects?: number
  n_regression_defects?: number
  n_valgrind_errors?: number
  n_behavior_valgrind_errors?: number
  n_regression_valgrind_errors?: number
  n_exploitability_factors_passing?: number
  n_exploitability_factors_total?: number
  started_at?: string | null
  ended_at?: string | null
  pending?: boolean
  started?: boolean
  stopped?: boolean
  ended?: boolean
  failed?: boolean
  has_critical_errors?: boolean
  description?: string
  name?: string
  phase?: RunPhaseCurrent
  owner?: string
  owner_name?: string
  target_slug?: string
  target_name?: string
  project_slug?: string
  project_name?: string
  api_run?: GetApiRun
}
export type RunsPage = {
  runs: RunListing[]
  count?: number
  api_count?: number
  code_count?: number
}
export type ProjectRunStat = {
  count?: number
  active?: number
}
export type Build = {
  created_at?: string
  updated_at?: string
  ci_url?: string
  branch_name?: string
  revision?: string
  n_targets?: number
  n_defects?: number
  n_testcase_reports?: number
  tests_run?: number
  n_blocks_covered?: number
  n_blocks_total?: number
}
export type BuildList = {
  builds?: Build[]
  count?: number
}
export type RunGetSchemaWithTargetSlug = {
  runs?: RunGetSchemaWithMetadata[]
  count?: number
}
