import RcPagination, { PaginationProps } from 'rc-pagination'
import { ReactNode, useState } from 'react'
import { Button } from '@chakra-ui/react'
import ArrowForwardIcon from '@material-design-icons/svg/sharp/arrow_forward.svg?react'
import ArrowBackIcon from '@material-design-icons/svg/sharp/arrow_back.svg?react'

type Props = PaginationProps & {
  /** Set to true to display the loading state. */
  isLoading?: boolean
  /** Renders an exact page component */
  renderPageItem: (current: number, isDisabled: boolean, isLoading: boolean) => ReactNode
  /** Sets the current page number, and puts the component into controlled mode */
  current?: number
}

type ItemType = 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next'

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : /* eslint-disable  @typescript-eslint/no-explicit-any */
      (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || 'en'

function calculatePage(pageSize: number, total: number) {
  return Math.floor((total - 1) / pageSize) + 1
}

const itemRender = (
  renderPageItem: (current: number, isDisabled: boolean, isLoading: boolean) => ReactNode,
  isLoading: boolean,
  state: number | undefined,
  total: number,
  pageSize: number
) => {
  // eslint-disable-next-line react/display-name
  return (current: number, type: ItemType, element: ReactNode) => {
    const isDisabled = !current || isLoading
    if (type === 'page') {
      return renderPageItem(current, isDisabled, isLoading)
    }
    if (type === 'prev') {
      return (
        <Button size="md" variant="outline" colorScheme="gray" leftIcon={<ArrowBackIcon />} isDisabled={isDisabled}>
          Previous
        </Button>
      )
    }
    if (type === 'next') {
      // rc-pagination doesn't pass in a disabled flag so we have to compute it ourselves
      const totalPages = calculatePage(pageSize, total)
      const hasNext = state && state < totalPages
      return (
        <Button size="md" variant="outline" colorScheme="gray" rightIcon={<ArrowForwardIcon />} isDisabled={isDisabled || !hasNext}>
          Next
        </Button>
      )
    }
    if (type === 'jump-next' || type == 'jump-prev') {
      return (
        <Button size="md" variant="outline" colorScheme="gray" isDisabled={isDisabled}>
          ...
        </Button>
      )
    }
    return element
  }
}

export function Pagination({ renderPageItem, isLoading = false, total = 1, pageSize = 15, onChange, ...rest }: Props) {
  const locale = getNavigatorLanguage()
  // track the state to disable the `Next` button
  // since rc-pagination does not allow any custom
  // renderer to know enough about that decision
  const [state, setState] = useState(rest.current)

  const handleChange = (page: number, pageSize: number) => {
    setState(page)
    if (onChange) {
      onChange(page, pageSize)
    }
  }

  const displayTotal = isLoading ? 1 : total

  return (
    <RcPagination
      className="rc-pagination"
      itemRender={itemRender(renderPageItem, isLoading, state, displayTotal, pageSize)}
      onChange={handleChange}
      locale={locale}
      total={displayTotal}
      pageSize={pageSize}
      {...rest}
    />
  )
}
