export type StatusParam = 'all' | 'running' | 'pending' | 'completed' | 'failed' | 'stopped'

export const statusOptions: Array<{ key: string; text: string; value: StatusParam }> = [
  {
    key: 'All Runs',
    text: 'All Runs',
    value: 'all'
  },
  {
    key: 'Active Runs',
    text: 'Active Runs',
    value: 'running'
  },
  {
    key: 'Pending Runs',
    text: 'Pending Runs',
    value: 'pending'
  },
  {
    key: 'Completed Runs',
    text: 'Completed Runs',
    value: 'completed'
  },
  {
    key: 'Failed Runs',
    text: 'Failed Runs',
    value: 'failed'
  },
  {
    key: 'Stopped Runs',
    text: 'Stopped Runs',
    value: 'stopped'
  }
]

export const getRunNumberUrl = (owner: string, projectSlug: string, targetSlug: string, runNumber: number) => {
  return `/api/v2/owner/${owner}/project/${projectSlug}/target/${targetSlug}/run/${runNumber}`
}

export const formatRunRepository = (repositoryUrl: string) => {
  let formatted = ''
  if (repositoryUrl?.startsWith('http')) {
    const parts = repositoryUrl.split('/')
    const userName = parts.slice(-1)[0]
    const repoName = parts.pop()

    formatted = `${userName}/${repoName}`
  } else {
    formatted = repositoryUrl?.substring(repositoryUrl.indexOf(':') + 1)
  }

  return formatted.replaceAll('.git', '')
}

const REPOSITORY_MAPPING: Record<string, string> = {
  github: 'GitHub',
  gitlab: 'GitLab',
  bitbucket: 'Bitbucket'
}

export const getRepositorySource = (repositoryUrl: string) => {
  const sources = Object.keys(REPOSITORY_MAPPING)
  const urlInput = repositoryUrl.toLowerCase()

  for (let i = 0; i < sources.length; i++) {
    if (urlInput.includes(sources[i])) {
      return REPOSITORY_MAPPING[sources[i]]
    }
  }

  return 'Git'
}

export const getRunSource = (repositoryUrl: string | undefined | null, cliVersion: string | undefined | null) => {
  if (repositoryUrl) {
    return formatRunRepository(repositoryUrl)
  }

  if (cliVersion) {
    return 'Mayhem CLI'
  }

  return 'Mayhem UI'
}

export const RUN_STATUSES = ['Active', 'Completed', 'Failed', 'Stopped']

export const RUN_TYPES = ['api', 'code']

export const RUN_STATUS_MAPPING: Record<string, string> = { Active: 'Running' }
