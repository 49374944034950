import { useState } from 'react'

import ProjectFilterDropdown from './ProjectFilterDropdown'

import { useGetAllProjectsQuery } from '@/redux/api/projects'
import { cleanInteger } from '@/util/numbers'
import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'
import { useQuery } from '@/hooks'

const DEFAULT_PAGE = 1

export default function ProjectAdminFilterDropdown() {
  const query = useQuery()
  const [search, setSearch] = useState('')

  const perPage = cleanInteger(query.get('perPage') || DEFAULT_PER_PAGE)
  const [page, setPage] = useState(DEFAULT_PAGE)

  const {
    data = {},
    isFetching,
    isError
  } = useGetAllProjectsQuery({
    perPage,
    q: search,
    offset: (page - 1) * perPage
  })

  return (
    <ProjectFilterDropdown
      data={data}
      isFetching={isFetching}
      isError={isError}
      onQueryInputChange={(query: string) => {
        setPage(DEFAULT_PAGE)
        setSearch(query)
      }}
      onScrollEnd={() => {
        setPage((page) => page + 1)
      }}
    />
  )
}
