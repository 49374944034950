import { useCallback } from 'react'

import { Card, CardBody, CardFooter, CardHeader } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'

import { useQuery } from '../../hooks'
import { stringifyUrl } from '../../util/location'
import { cleanInteger } from '../../util/numbers'
import { DefectRow } from '../defect/DefectRow'
import { FilterMenuButton } from '../../components/FilterMenuButton'
import { useGetProjectDefectsQuery } from '../../redux/api/defects'

type Props = {
  owner: string
  projectSlug: string
  targetSlug: string
}

const DEFAULT_TARGET_DEFECTS_PAGESIZE_OPTIONS = [5, 10, 20]

const targetDefectsPageUrlPrefix = 'defects-'

export function TargetDefects({ owner, projectSlug, targetSlug }: Props) {
  const [_location, setLocation] = useLocation()

  const query = useQuery()
  const runFilter = query.get('run') || 'all'
  const page = cleanInteger(query.get(`${targetDefectsPageUrlPrefix}page`), 1)
  const perPage = cleanInteger(query.get(`${targetDefectsPageUrlPrefix}perPage`), 5)

  const {
    isFetching,
    currentData: defectResponse,
    isError
  } = useGetProjectDefectsQuery({ owner, projectSlug, target: targetSlug, run: runFilter === 'all' ? undefined : runFilter, page, perPage: perPage })

  const selectRunFilter = useCallback(
    (newSelectedRunFilter: string | string[]) => {
      if (newSelectedRunFilter instanceof Array) {
        newSelectedRunFilter = newSelectedRunFilter[0]
      }

      const filters = { run: newSelectedRunFilter !== 'all' ? newSelectedRunFilter : null }

      setLocation(stringifyUrl(`/${owner}/${projectSlug}/${targetSlug}`, filters), { replace: true })
    },
    [owner, projectSlug, setLocation, targetSlug]
  )

  // run filter options
  const runFilterOptions = [
    {
      key: 'Latest Run',
      text: 'Latest Run',
      value: 'latest'
    },
    {
      key: 'All Runs',
      text: 'All Runs',
      value: 'all'
    }
  ]

  if (!(runFilter === 'latest' || runFilter === 'all')) {
    runFilterOptions.push({
      key: 'specific run',
      text: `Run ${runFilter}`,
      value: runFilter
    })
  }

  const defects = defectResponse?.defects || []

  return (
    <Card>
      <CardHeader>
        <FilterMenuButton
          options={runFilterOptions}
          value={runFilter}
          defaultText="All Runs"
          onValueChange={selectRunFilter}
          isDisabled={isFetching}
        />
      </CardHeader>
      <CardBody>
        {defects.map((defect) => (
          <DefectRow
            key={defect.defect_number}
            owner={owner}
            projectSlug={projectSlug}
            defect={defect}
            overrideUrl={`/${owner}/${projectSlug}/${targetSlug}/-/defects/${defect.defect_number}`}
          />
        ))}
        <ListDefaults isLoading={isFetching} nItems={defects.length} itemName="defect" isFail={isError} />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination
          isLoading={isFetching}
          total={defectResponse?.count || 0}
          prefix={targetDefectsPageUrlPrefix}
          pageSizeOptions={DEFAULT_TARGET_DEFECTS_PAGESIZE_OPTIONS}
        />
      </CardFooter>
    </Card>
  )
}
