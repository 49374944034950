import { RUN_TYPES } from '@/features/runs/utils'
import QueryParamFilterDropdown from '@/components/Combobox/QueryParamFilterDropdown'

const TAG_OPTIONS = RUN_TYPES.map((runType) => ({
  id: runType,
  value: runType === 'api' ? runType.toUpperCase() : runType.charAt(0).toUpperCase() + runType.slice(1)
}))

export default function ProjectTagsFilterDropdown() {
  return <QueryParamFilterDropdown inputItems={TAG_OPTIONS} defaultInputPlaceholder="Tags" param="tags" multiSelect />
}
