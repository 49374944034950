import { Box, Card, CardBody, CardBodyProps, CardHeader, Heading } from '@chakra-ui/react'
import React from 'react'

interface Props {
  children: React.ReactNode
  actions: React.ReactNode
  title: string
  headerActions?: React.ReactNode
  CardBodyProps?: CardBodyProps
}

export function TableCard({ actions, children, title, headerActions, CardBodyProps }: Props) {
  return (
    <Card>
      <CardHeader paddingBottom={0} paddingX={5}>
        <Heading variant="cardHeading" textTransform="capitalize">
          {title}
        </Heading>
        <Box>{headerActions}</Box>
      </CardHeader>
      <CardHeader padding={4} gap={3} paddingY={6}>
        {actions}
      </CardHeader>
      <CardBody paddingTop={4} {...CardBodyProps}>
        {children}
      </CardBody>
    </Card>
  )
}
